// SettingsAdmin.tsx
import { useAccountSettingsQuery } from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { SettingsContainer } from "../UserSettings/Core/SettingsContainer";
import "./SettingsAdmin.css";
import { SettingsAdminContent } from "./SettingsAdminContent/SettingsAdminContent";

export const SettingsAdmin = () => {
  const { data, loading, error } = useAccountSettingsQuery();

  if (loading) return <BoxLoader />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="SettingsAdmin">
      <div className="SettingsAdmin__Title">
        <h1 className="SettingsAdmin__Title-Text">
          Administratorindstillinger
        </h1>
      </div>
      <div className="Settings__dividerbar" />
      <div className="SettingsAdmin__Content">
        <SettingsContainer headerTexts={["Handling", ""]}></SettingsContainer>
        <div className="SettingsAdmin__Elements">
          <div className="SettingsAdminContent__Element">
            <p>Synkroniser ordrer</p>
            <label>Start en manuel synkronisering af ordrer</label>
          </div>
          <div className="SettingsAdminContent__Element">
            {data && <SettingsAdminContent settings={data.accountSettings} />}
          </div>
        </div>        
      </div>
    </div>
  );
};
