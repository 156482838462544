import { useEffect, useState } from "react";
import {
  OrderItemInput,
  OrderItemStatus,
  OrderItem as OrderItemType,
} from "../../Generated/graphql";
import { Checkbox } from "../Checkbox/Checkbox";
import { Icon, IconName } from "../Icon/Icon";
import IconList from "../Icon/Icon-list";
import { OrderItem } from "../OrderItem/OrderItem";
import "./OrderDetails.css";

interface IOrderDetailsProps {
  showMe: boolean;
  orderLines: OrderItemType[];
  openScan: () => void;
  windowWidth: number;
  changeItemAmount: (productNumber: string, amount: number) => void;
  addItem: (items: OrderItemInput) => void;
  addingToStock: boolean;
  removeItems: (productNumbers: string[]) => void;
  selectItems: (productNumbers: string[]) => void;
  selectedItems: string[];
  triggerUpdateOrderItems: () => void;
  changeExcludedState: (productNumber: string, excluded: boolean) => void;
}

export const OrderDetails = ({
  showMe,
  orderLines,
  openScan,
  windowWidth,
  changeItemAmount,
  addItem,
  addingToStock,
  removeItems,
  selectItems,
  selectedItems,
  triggerUpdateOrderItems,
  changeExcludedState,
}: IOrderDetailsProps) => {
  const changeAllItems = () => {
    if (selectedItems.length > 0) {
      removeItems(selectedItems);
    } else {
      selectItems(
        orderLines.reduce<string[]>((acc, line) => {
          if (
            line.status === OrderItemStatus.Backorder ||
            line.status === OrderItemStatus.Completed
          ) {
            return acc;
          }
          return [...acc, line.productNumber];
        }, [])
      );
    }
  };

  // whilst the component is mounted, it must keep the ordering of the items, even if they change state
  const [stateOrderlines, setStateOrderlines] = useState<OrderItemType[]>(
    orderLines
  );

  useEffect(() => {
    // Update the state orderlines when props change,
    // this way we have a local representation in the current order,
    // but the props can be in whatever order we want
    const updatedLines = stateOrderlines.map((line) => {
      const newLine = orderLines.find((ol) => ol.id === line.id);
      if (newLine) {
        return newLine;
      }
      return line;
    });

    setStateOrderlines(updatedLines);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLines]);

  return (
    <div className="OrderDetails">
        {addingToStock ?
        <div className="OrderDetails__addToInventory">
        <div className="OrderDetails__scanIn" onClick={() => openScan()}>
          <div className="OrderDetails__icon">
            <Icon name="scanActive" width={15} />
          </div>
          <div className="OrderDetails__scan-text">Scan varer</div>
        </div>
        <div
          className="OrderDetails__addToInventory-button"                
        >
          <Icon name={IconList.add as IconName} width={12} />
          <div className="OrderDetails__addToInventory-text">
            Godkend valgte
          </div>
        </div>
        <div className="OrderDetails__addToInventory-checkbox">
          <Checkbox
            checked={selectedItems.length === orderLines.length}
            partiallyChecked={selectedItems.length > 0}
            change={changeAllItems}
          />
        </div>
      </div>
        :
        <div className="OrderDetails__addToInventory">
          <div className="OrderDetails__scanIn" onClick={() => openScan()}>
            <div className="OrderDetails__icon">
              <Icon name="scanActive" width={15} />
            </div>
            <div className="OrderDetails__scan-text">Scan varer</div>
          </div>
          <div
            className="OrderDetails__addToInventory-button"
            onClick={triggerUpdateOrderItems}          
          >
            <Icon name="addBlue" width={12} />
            <div className="OrderDetails__addToInventory-text">
              Godkend valgte
            </div>
          </div>
          <div className="OrderDetails__addToInventory-checkbox">
            <Checkbox
              checked={selectedItems.length === orderLines.length}
              partiallyChecked={selectedItems.length > 0}
              change={changeAllItems}
            />
          </div>
        </div>
        }
     
      <div className="OrderDetails__items">
        {stateOrderlines.map((item) => (
          <OrderItem
            windowWidth={windowWidth}
            item={item}
            key={item.id}
            addItem={addItem}
            changeItemAmount={changeItemAmount}
            changeExcludedState={changeExcludedState}
            isSelected={
              !!selectedItems.find(
                (productNumber) => productNumber === item.productNumber
              )
            }
            removeItem={(productNumber) => removeItems([productNumber])}
            selectItem={(productNumber) => selectItems([productNumber])}
          />
        ))}
      </div>
    </div>
  );
};
