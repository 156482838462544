import React from "react";
import IconList from "./Icon-list";
import "./Icon.css";

export type IconName = keyof typeof IconList;


interface IIconProps {
  name: IconName | "";
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
  style?: React.CSSProperties;
  pathStyle?: React.CSSProperties; // Add this new prop
  opacity?: number;
  [x: string]: any;
}

export const Icon: React.FC<IIconProps> = ({
  name,
  width,
  height,
  alt,
  className,
  style,
  pathStyle,
  opacity = 100,
  ...props
}) => {
  const styles: React.CSSProperties = {
    ...style,
    width: width ? `${width}px` : undefined,
    height: height ? `${height}px` : undefined,
    opacity: opacity / 100,
  };



  const iconContent = name && name in IconList ? IconList[name as IconName] : "";

  const svgContent = pathStyle 
  ? iconContent.replace('<path', `<path style="fill: ${pathStyle.fill}"`)
  : iconContent;


  return (
    <div
      className={`Icon ${className || ""}`}
      style={styles}
      dangerouslySetInnerHTML={{ __html: svgContent }}
      {...props}
    />
  );
};

Icon.defaultProps = {
  name: "ovalLoader" as IconName
}

export default Icon;
