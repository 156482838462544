// @ts-ignore
import * as FileSaver from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
// @ts-ignore
import { Calendar } from "react-calendar";
import ReactToPrint from "react-to-print";
import * as XLSX from "xlsx";
import { useInventoryValueOnDateLazyQuery } from "../../Generated/graphql";
import { capitalizeString } from "../../Utils/capitalizeString";
import { formatExcelData } from "../../Utils/formatExcelData";
import { useOutsideClick } from "../../Utils/useOutsideClick";
import { PrintableInventory } from "../ExportComponent/ExportComponent";
import { Icon } from "../Icon/Icon";
import "./ExportDatepicker.css";

type Props = {
  buttonText: string;
  buttonTexts: { [key: string]: string };
  setShow: (newValue: boolean) => void;
  shown: boolean;
  type: any;
  types: string[];
  user: any;
};

export const ExportDatepicker: FC<Props> = ({
  buttonText,
  buttonTexts,
  setShow,
  shown,
  type,
  types,
  user,
}) => {
  const [
    fetchStockForDate,
    { called, loading, data },
  ] = useInventoryValueOnDateLazyQuery();
  const ref = useRef<HTMLDivElement | null>(null);
  const printRef = useRef<any | null>(null);
  const [date, setDate] = useState(new Date());
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    fetchStockForDate({ variables: { date: moment(date).format() } });
  }, [date, fetchStockForDate]);

  useOutsideClick(ref, () => {
    if (shown) {
      setShow(false);
    }
  });

  const handleDownload = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (data && called && !loading) {
      // Get data from API called data
      const rawData = data.inventoryValueOnDate ?? [];
      // Format data.
      const formattedData = formatExcelData(rawData);

      // Magic?
      const ws = XLSX.utils.json_to_sheet(formattedData);
      var amountCol = XLSX.utils.decode_col("E"); //decode_col converts Excel col name to an integer for col #
      var amountFmt = '###0 "Stk."'; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

      if (!ws["!ref"]) {
        return;
      }

      /* get worksheet range */
      var amountRange = XLSX.utils.decode_range(ws["!ref"]);
      for (var i = amountRange.s.r + 1; i <= amountRange.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        var amountRef = XLSX.utils.encode_cell({ r: i, c: amountCol });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[amountRef]) continue;
        /* `.t == "n"` for number cells */
        if (ws[amountRef].t !== "n") continue;
        /* assign the `.z` number format */
        ws[amountRef].z = amountFmt;
      }

      // Common formatting value
      const fmt = "#,##0.00"; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

      // Handle Total price column
      const colNum = XLSX.utils.decode_col("F"); //decode_col converts Excel col name to an integer for col #

      /* get worksheet range */
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let j = range.s.r + 1; j <= range.e.r; ++j) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: j, c: colNum });
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) continue;
        /* `.t == "n"` for number cells */
        if (ws[ref].t !== "n") continue;
        /* don't format 0 */
        if(ws[ref].v <= 0) continue;
        /* assign the `.z` number format */
        ws[ref].z = fmt;
      }

      // Handle pricePerUnit column
      const colNumPricePer = XLSX.utils.decode_col("G"); //decode_col converts Excel col name to an integer for col #

      /* get worksheet range */
      const rangePricePer = XLSX.utils.decode_range(ws["!ref"]);
      for (let k = rangePricePer.s.r + 1; k <= range.e.r; ++k) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const refPricePer = XLSX.utils.encode_cell({ r: k, c: colNumPricePer });
        /* if the particular row did not contain data for the column, the cell will not be generated */

        console.log({refPricePer})
        console.log({price: ws[refPricePer]})

        if (!ws[refPricePer]) continue;
        /* `.t == "n"` for number cells */
        if (ws[refPricePer].t !== "n") continue;
        /* don't format 0 */
        if(ws[refPricePer].v <= 0) continue;
        /* assign the `.z` number format */
        ws[refPricePer].z = fmt;
      }

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const saveData = new Blob([excelBuffer], { type: fileType });
      // Get excel file
      FileSaver.saveAs(
        saveData,
        "Lageroversigt " + moment(date).format("DD-MM-YYYY") + fileExtension
      );
    }
  };

  const generatePDF = async (ref: any) => {
    setGenerating(true);
    if (!ref.current) {
      return;
    }

    // Check if data is available
    if (data && called && !loading) {
      // Clone the content and add the required class
      const clonedContent = ref.current.cloneNode(true);
      clonedContent.classList.add("PrintableInventory__table");
      document.body.appendChild(clonedContent); // Temporarily append to ensure styles are applied

      // Use html2canvas to capture the styled content with reduced scale for smaller image size
      const canvas = await html2canvas(clonedContent, { scale: 2 }); // Adjust scale as needed
      document.body.removeChild(clonedContent); // Remove the temporary element

      const imgWidthPx = canvas.width; // Width of the captured image in pixels
      const imgHeightPx = canvas.height; // Height of the captured image in pixels

      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm
      const scaleFactor = pdfWidth / imgWidthPx; // Scale factor to fit width to A4

      const doc = new jsPDF("p", "mm", "a4");

      // Calculate the number of pages
      const totalPages = Math.ceil((imgHeightPx * scaleFactor) / pdfHeight);

      for (let i = 0; i < totalPages; i++) {
        const yOffsetPx = (i * pdfHeight) / scaleFactor; // Calculate y offset in pixels
        const pageHeightPx = Math.min(
          pdfHeight / scaleFactor,
          imgHeightPx - yOffsetPx
        ); // Height for current page in pixels

        // Create a temporary canvas for the current page
        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = imgWidthPx;
        pageCanvas.height = pageHeightPx;

        // Draw the current page part on the temporary canvas
        const pageCtx = pageCanvas.getContext("2d");
        pageCtx?.drawImage(
          canvas,
          0,
          yOffsetPx,
          imgWidthPx,
          pageHeightPx,
          0,
          0,
          imgWidthPx,
          pageHeightPx
        );

        // Convert the temporary canvas to image data
        const pageImgData = pageCanvas.toDataURL("image/jpeg", 0.8);

        // Add the image to the PDF
        if (i > 0) {
          doc.addPage();
        }

        const margin = 10;

        doc.addImage(
          pageImgData,
          "JPEG",
          margin,
          margin,
          pdfWidth - 2 * margin,
          pdfHeight - 2 * margin
        );
      }

      // Save the PDF with a compressed filename
      doc.save("Lageroversigt " + moment(date).format("DD-MM-YYYY") + ".pdf");
      setGenerating(false);
    }
  };

  return (
    <div className="ExportDatepicker" ref={ref}>
      <div className="ExportDatepicker__calendar">
        <Calendar
          value={date}
          onChange={(newDate: Date) => {
            const formatDate = new Date(newDate.setHours(23, 59, 59));
            setDate(formatDate);
          }}
          minDetail="month"
          maxDate={new Date()}
          formatShortWeekday={(locale: string, day: Date) => {
            return day
              .toLocaleDateString(locale, { weekday: "long" })
              .toUpperCase()
              .substring(0, 1);
          }}
          locale="da"
          tileContent={({
            view,
            date,
          }: {
            view: "month" | "year";
            date: Date;
          }) => {
            return (
              <div className="ExportDatepicker__calendar-tile-highlighter">
                <div className="ExportDatepicker__calendar-tile-text">
                  {view === "month" && date.getDate()}
                  {view === "year" &&
                    capitalizeString(
                      new Intl.DateTimeFormat("da-DK", {
                        month: "long",
                      }).format(date)
                    )}
                </div>
              </div>
            );
          }}
          navigationLabel={({ label }: { label: string }) => {
            return capitalizeString(label);
          }}
          nextLabel={<CalendarNextLabel />}
          prevLabel={<CalendarPrevLabel />}
        />
      </div>
      <div className="ExportDatepicker__actions-wrapper">
        {types?.map((type) => {
          if (type === "xlsx") {
            return (
              <div
                key={`type-${type}`}
                className="ExportDatepicker__action-button"
                style={
                  loading
                    ? { backgroundColor: "gray", pointerEvents: "none" }
                    : {}
                }
                onClick={() => handleDownload()}
              >
                <div className="ExportDatepicker__button">
                  {loading ? (
                    <div className="ExportDatepicker__loader">
                      <Icon name="buttonSpinner" width={18} height={18} />
                    </div>
                  ) : (
                    <>
                      <Icon name="exportItems" width={14} />{" "}
                      <div className="ExportDatepicker__button-text">
                        <p>{buttonTexts[type] || buttonText}</p>
                        <p>.{type}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          }
          if (type === "print") {
            return (
              <>
                <ReactToPrint
                  copyStyles={false}
                  bodyClass={"PrintableInventory__table"}
                  trigger={() => (
                    <div
                      className="ExportDatepicker__action-button"
                      style={
                        loading
                          ? { backgroundColor: "gray", pointerEvents: "none" }
                          : {}
                      }
                    >
                      <div className="ExportDatepicker__button">
                        {loading ? (
                          <div className="ExportDatepicker__loader">
                            <Icon name="buttonSpinner" width={18} height={18} />
                          </div>
                        ) : (
                          <>
                            <Icon name="printItems" width={14} />{" "}
                            <div className="ExportDatepicker__button-text">
                              {buttonTexts[type] || buttonText}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  content={() => printRef.current}
                />
                <div className="PrintableInventory__hidden">
                  {data && data.inventoryValueOnDate && !loading && (
                    <PrintableInventory
                      ref={printRef}
                      date={date}
                      stockItems={data.inventoryValueOnDate ?? []}
                      accountName={user}
                    />
                  )}
                </div>
              </>
            );
          }
          if (type === "pdf") {
            return (
              <div
                key={`type-${type}`}
                className="ExportDatepicker__action-button"
                style={
                  loading
                    ? { backgroundColor: "gray", pointerEvents: "none" }
                    : {}
                }
                onClick={() => generatePDF(printRef)}
              >
                <div className="ExportDatepicker__button">
                  {loading ? (
                    <div className="ExportDatepicker__loader">
                      <Icon name="buttonSpinner" width={18} height={18} />
                    </div>
                  ) : generating ? (
                    <div className="ExportDatepicker__loader">
                      <Icon name="buttonSpinner" width={18} height={18} />
                    </div>
                  ) : (
                    <>
                      <Icon name="exportItems" width={14} />{" "}
                      <div className="ExportDatepicker__button-text">
                        <p>{buttonTexts[type] || buttonText}</p>
                        <p>.{type}</p>
                      </div>
                    </>
                  )}
                </div>
                <div className="PrintableInventory__hidden">
                  {data && data.inventoryValueOnDate && !loading && (
                    <PrintableInventory
                      ref={printRef}
                      date={date}
                      stockItems={data.inventoryValueOnDate ?? []}
                      accountName={user}
                    />
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

const CalendarNextLabel = () => (
  <div className="CalendarNextLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);

const CalendarPrevLabel = () => (
  <div className="CalendarPrevLabel CalendarLabel">
    <Icon name="arrowDown" width={14} />
  </div>
);
