// frontend/src/GraphQL/Queries/inventoryTurnover.query.ts
import gql from "graphql-tag";

export const INVENTORY_TURNOVER = gql`
  query InventoryTurnover(
    $startDate: String
    $endDate: String
    $interval: InventoryInterval = LAST_30_DAYS
  ) {
    inventoryTurnover(
      startDate: $startDate
      endDate: $endDate
      interval: $interval
    ) {
      globalTurnover
      productTurnover {
        value
        productNumber
        productName
        category
      }
      categoryTurnover {
        value
        category
      }
      startDate
      endDate
    }
  }
`;
