import { useEffect, useState } from "react";
import {
  InventoryItemHistoryDocument,
  useInventoryItemHistoryQuery,
} from "../../Generated/graphql";
import { ShowMoreButton } from "../ShowMoreButton/ShowMoreButton";
import "./ItemHistory.css";
import { ItemHistoryEntry } from "./ItemHistoryEntry/ItemHistoryEntry";

type Props = {
  productNumber: string;
  unit: string;
  canEdit: boolean;
};

export const ItemHistory = ({ productNumber, unit, canEdit }: Props) => {
  const { data, loading, error, fetchMore } = useInventoryItemHistoryQuery({
    variables: { productNumber, offset: 0, limit: 10 },
    fetchPolicy: "network-only",
  });

  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.inventoryItemHistory.length < 10) {
      setShowButton(false);
    }
    if (data && data.inventoryItemHistory.length === 10) {
      setShowButton(true);
    }
  }, [data]);

  const handleFetchMore = () => {
    fetchMore({
      query: InventoryItemHistoryDocument,
      variables: {
        productNumber: productNumber,
        offset: data?.inventoryItemHistory.length
          ? data.inventoryItemHistory.length
          : 0,
        limit: 10,
      },
      updateQuery(prev, options) {
        if (!options.fetchMoreResult) {
          return prev;
        }
        if (options.fetchMoreResult?.inventoryItemHistory?.length < 10) {
          setShowButton(false);
        }

        return {
          inventoryItemHistory: [
            ...prev.inventoryItemHistory,
            ...options.fetchMoreResult.inventoryItemHistory,
          ],
        };
      },
    });
  };

  if (!loading && !error && data && data.inventoryItemHistory) {
    return (
      <div className="ItemHistory">
        <div className="ItemHistory__header">
          <div className="ItemHistory__title">
            <div className="ItemHistory__title-text">Historik</div>
          </div>
        </div>
        <div className="ItemHistory__history">
          {data.inventoryItemHistory.map((entry) => (
            <ItemHistoryEntry
              key={entry.id}
              entry={entry}
              unit={unit}
              canEdit={canEdit}
              relatedEntries={data.inventoryItemHistory.filter(
                (item) => item.id !== entry.id
              )}
            />
          ))}
        </div>
        {showButton && (
          <div className="ItemHistory__history--show-more">
            <ShowMoreButton onClick={handleFetchMore}>Vis flere</ShowMoreButton>
          </div>
        )}
      </div>
    );
  } else {
    return <div />;
  }
};
