import { clamp, clone, debounce } from "lodash";
import { ChangeEvent, Component, useMemo } from "react";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "react-tooltip-lite";
import {
  AutomaticOrdering,
  InventoryItem,
  MutationUpdateInventoryItemArgs,
  OrderItemStatus,
  UpdateCustomItemMutationVariables,
  useAccountSettingsQuery,
  useAddItemsToCartMutation,
  useDeleteInventoryItemMutation,
  useInventoryQuery,
  useOrdersQuery,
  useUpdateCustomItemMutation,
  useUpdateInventoryItemMutation,
  useUserQuery
} from "../../Generated/graphql";
import { ICartItem } from "../../Interfaces/ICart";
import { OrderButtonEnum } from "../../Utils/OrderButtonEnum";
import { noop } from "../../Utils/noop";
import { ChangeInStockModal } from "../ChangeInStockModal/ChangeInStockModal";
import { DeleteStockModal } from "../DeleteStockModal/DeleteStockModal";
import { Icon, IconName } from "../Icon/Icon";
import { ItemHistory } from "../ItemHistory/ItemHistory";
import { ListItem } from "../ListItem/ListItem";
import { ModalButton } from "../ModalButton/ModalButton";
import { NumberInput } from "../NumberInput/NumberInput";
import { OrderButton } from "../OrderButton/OrderButton";
import { PrintBarcode } from "../PrintBarcode/PrintBarcode";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { Select, SelectOption } from "../Select/Select";
import "./EditItem.css";

export const EditItem = () => {
  const { data } = useInventoryQuery();
  const { data: accountData } = useAccountSettingsQuery();
  const { data: userData } = useUserQuery();
  const { data: orderData} = useOrdersQuery()
  const [updateStockItem] = useUpdateInventoryItemMutation();
  const [updateCustomStockItem] = useUpdateCustomItemMutation();
  const [deleteStockItem] = useDeleteInventoryItemMutation();
  const [addItemsToCart] = useAddItemsToCartMutation();

  const params = useParams<{ productId: string }>();
  const history = useHistory();

  const inventoryItem = useMemo(() => {
    if (data && data.inventory) {
      return data.inventory.find(
        (item) => item.productNumber === params.productId
      );
    } else {
      return null;
    }
  }, [data, params.productId]);

  if (!inventoryItem) {
    return null;
  }

  const canEdit = accountData?.accountSettings?.usersCanEdit;
  const role = userData?.user?.role;

  const isInBackOrder = orderData?.orders?.some(({orderLines}) => orderLines.some((orderLine) => orderLine.productNumber === inventoryItem.productNumber && orderLine?.status === OrderItemStatus.Backorder)) || false

  const allowEdit =
    (canEdit && role === "EMPLOYEE") || role === "ADMIN" ? true : false;

  // Return the custom renderer
  return (
    <EditItemContent
      inventoryItem={inventoryItem}
      history={history}
      hasGlobalAutomaticOrdering={
        accountData?.accountSettings?.hasAutomaticOrdering ?? false
      }
      // @ts-ignore
      updateStock={updateStockItem}
      updateCustomStock={updateCustomStockItem}
      deleteStock={deleteStockItem}
      addItemsToCart={addItemsToCart}
      canEdit={allowEdit}
      isInBackOrder={isInBackOrder}
    />
  );
};

type UpdateStockArgs = {
  variables: MutationUpdateInventoryItemArgs;
};
type UpdateCustomStockArgs = {
  variables: UpdateCustomItemMutationVariables;
};

interface IDeleteStockArgs {
  variables: {
    productNumber: string;
  };
}

interface IAddItemsToCart {
  variables: {
    items: ICartItem[];
  };
}

interface EditItemContentProps {
  inventoryItem: InventoryItem;
  history: RouteComponentProps["history"];
  hasGlobalAutomaticOrdering: boolean;
  updateStock: (args: UpdateStockArgs) => Promise<any>;
  updateCustomStock: (args: UpdateCustomStockArgs) => Promise<any>;
  deleteStock: (args: IDeleteStockArgs) => Promise<any>;
  addItemsToCart: (args: IAddItemsToCart) => Promise<any>;
  canEdit: boolean;
  isInBackOrder: boolean;
}

interface EditItemContentState {
  editing: boolean;
  categoryList: { label: string; value: string }[];
  unitList: { label: string; value: string }[];
  orderButtonState: OrderButtonEnum;
  savedRecently: boolean;
  savedRecentlyIcon: string;
  item: InventoryItem;
  manualOrder: number;
  showInStockModal: boolean | null;
  showDeleteModal: boolean;
  justDeleted: boolean;
  startingMin: number;
  startingMax: number;
}

export class EditItemContent extends Component<
  EditItemContentProps,
  EditItemContentState
> {
  constructor(props: EditItemContentProps) {
    super(props);

    this.state = {
      editing: false, // Show units?
      categoryList: [
        {
          label: "Vådlager",
          value: "Vådlager",
        },
        {
          label: "Tørlager",
          value: "Tørlager",
        },
        {
          label: "Slatlager",
          value: "Slatlager",
        },
      ],
      orderButtonState: OrderButtonEnum.READY,
      savedRecently: false,
      savedRecentlyIcon: "",
      item: props.inventoryItem,
      manualOrder: 1,
      showInStockModal: null,
      showDeleteModal: false,
      justDeleted: false,
      startingMin: clone(props.inventoryItem.minimum),
      startingMax: clone(props.inventoryItem.maximum),
      unitList: [
        {
          label: "Stykvis (stk.)",
          value: "stk",
        },
        {
          label: "Ruller (rl.)",
          value: "rl",
        },
        {
          label: "Pakker (pk.)",
          value: "pk",
        },
      ],
    };

    this.updateStock = debounce(this.updateStock, 500, {
      trailing: true,
      leading: false,
    });
    this.debouncedGoBack = debounce(this.debouncedGoBack, 1000, {
      leading: true,
      trailing: false,
    });
  }

  updateStock(args: UpdateStockArgs,origin?:string) {
    if(origin){
      
    }
    this.setState({
      savedRecently: true,
      savedRecentlyIcon: "buttonSpinner",
    });
    this.props
      .updateStock(args)
      .then(() => {
        setTimeout(() => {
          
          this.setState({
            savedRecently: true,
            savedRecentlyIcon: "checkmarkWhite",
          });
        }, 1000);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            savedRecently: false,
            savedRecentlyIcon: "",
          });
        }, 4000);
      });
  }

  updateCustomStock(args: UpdateCustomStockArgs,origin?:string) {
    if(origin){
      
    }
    this.setState({
      savedRecently: true,
      savedRecentlyIcon: "buttonSpinner",
    });
    this.props
      .updateCustomStock(args)
      .then(() => {
        
        setTimeout(() => {
          this.setState({
            savedRecently: true,
            savedRecentlyIcon: "checkmarkWhite",
          });
        }, 1000);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            savedRecently: false,
            savedRecentlyIcon: "",
          });
        }, 4000);
      });
  }

  toggleAutomaticOrderingState = () => {
    if (this.state.item.automaticOrderingState === AutomaticOrdering.Active) {
      this.setState(
        {
          item: {
            ...this.state.item,
            automaticOrderingState: AutomaticOrdering.Deactivated,
          },
        },
        () =>
          this.updateStock({
            variables: {
              productNumber: this.state.item.productNumber,
              action: "manual",
              automaticOrdering: this.state.item.automaticOrderingState,
              inStock: this.state.item.amount,
              maximum: this.state.item.maximum,
              minimum: this.state.item.minimum,
            },
          })
      );
    }

    if (
      this.state.item.automaticOrderingState === AutomaticOrdering.Deactivated
    ) {
      this.setState(
        {
          item: {
            ...this.state.item,
            automaticOrderingState: AutomaticOrdering.Active,
          },
        },
        () =>
          this.updateStock({
            variables: {
              productNumber: this.state.item.productNumber,
              action: "manual",
              automaticOrdering: this.state.item.automaticOrderingState,
              inStock: this.state.item.amount,
              maximum: this.state.item.maximum,
              minimum: this.state.item.minimum,
            },
          })
      );
    }
  };

  checkIfValueIsBelowMinimum = () => {
    if (this.state.item.minimum > this.state.item.maximum) {
      this.setState(
        (prevState) => ({
          item: {
            ...prevState.item,
            maximum: this.state.item.minimum,
          },
        }),
        () =>{
          if (this.props.inventoryItem.isCustom) {
            
            this.updateCustomStock({
              variables: {
                updateCustomItemId: this.state.item.productNumber,
                barcode: this.state.item.barcode,
                category: this.state.item.category,
                description: this.state.item.description,
                inStock: this.state.item.amount,
                name: this.state.item.name,
                price: this.state.item.price,
                supplier: this.state.item.supplier,
                units: this.state.item.unit,
              },
            });
  
            return;
          }
          this.updateStock({
            variables: {
              productNumber: this.state.item.productNumber,
              action: "manual",
              automaticOrdering: this.state.item.automaticOrderingState,
              inStock: this.state.item.amount,
              maximum: this.state.item.maximum,
              minimum: this.state.item.minimum,
            },
          })}
      );
    } else {
      if (this.props.inventoryItem.isCustom) {
        
        this.updateCustomStock({
          variables: {
            updateCustomItemId: this.state.item.productNumber,
            barcode: this.state.item.barcode,
            category: this.state.item.category,
            description: this.state.item.description,
            inStock: this.state.item.amount,
            name: this.state.item.name,
            price: this.state.item.price,
            supplier: this.state.item.supplier,
            units: this.state.item.unit,
          },
        });

        return;
      }
      this.updateStock({
        variables: {
          productNumber: this.state.item.productNumber,
          action: "manual",
          automaticOrdering: this.state.item.automaticOrderingState,
          inStock: this.state.item.amount,
          maximum: this.state.item.maximum,
          minimum: this.state.item.minimum,
        },
      });
    }
  };

  onTextValueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "manualOrder") {
      const valueAsNumber = parseInt(value, 10);
      if (!valueAsNumber) {
        return;
      }
      this.setState({ manualOrder: clamp(valueAsNumber, 1, 10000) });
    } else {
      this.setState({
        item: {
          ...this.state.item,
          [name]: value,
        },
      });
    }
  };

  onSelectValueChangeHandler = (value: any, name: any) => {
    
    this.setState(
      (prevState) => ({
        ...prevState,
        item: {
          ...prevState.item,
          [name]: value,
        },
      }),
      () => {
        if (this.props.inventoryItem.isCustom) {
          this.updateCustomStock({
            variables: {
              updateCustomItemId: this.state.item.productNumber,
              barcode: this.state.item.barcode,
              category: this.state.item.category,
              description: this.state.item.description,
              inStock: this.state.item.amount,
              name: this.state.item.name,
              price: this.state.item.price,
              supplier: this.state.item.supplier,
              units: this.state.item.unit,
            },
          });

          return;
        }

        this.updateStock({
          variables: {
            productNumber: this.state.item.productNumber,
            action: "manual",
            automaticOrdering: this.state.item.automaticOrderingState,
            inStock: this.state.item.amount,
            maximum: this.state.item.maximum,
            minimum: this.state.item.minimum,
          },
        },'valueChange');
      }
    );
  };

  TextInputField = ({ value = "", name = "", readOnly = false }) => {
    return (
      <input
        type="text"
        className="EditItem__TextInputField-input"
        value={value}
        name={name}
        readOnly={readOnly}
        onChange={(e) => {
          this.setState({ savedRecently: false });
          this.onTextValueChangeHandler(e);
        }}
        onBlur={() => {
          
          if (this.props.inventoryItem.isCustom) {
            
            this.updateCustomStock({
              variables: {
                updateCustomItemId: this.state.item.productNumber,
                barcode: this.state.item.barcode,
                category: this.state.item.category,
                description: this.state.item.description,
                inStock: this.state.item.amount,
                name: this.state.item.name,
                price: this.state.item.price,
                supplier: this.state.item.supplier,
                units: this.state.item.unit,
              },
            });

            return;
          }
          
          this.updateStock({
            variables: {
              productNumber: this.state.item.productNumber,
              action: "manual",
              automaticOrdering: this.state.item.automaticOrderingState,
              inStock: this.state.item.amount,
              maximum: this.state.item.maximum,
              minimum: this.state.item.minimum,
            },
          });
        }}
      />
    );
  };

  SelectInputField = ({
    value = "",
    options,
    name = "",
  }: {
    value: string;
    options: any[];
    name: string;
  }) => {
    return (
      <div className="EditItem__SelectInputField">
        <div className="EditItem__select">
          <Select
            change={(v: any) => this.onSelectValueChangeHandler(v, name)}
            value={value}
            type="editItem"
          >
            {options.map(({ label, value }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  setItem = (id: string, productList: any[]) => {
    const foundItem = productList.find((x) => x.id === id);
    this.setState((prevState) => ({
      ...prevState,
      ...foundItem,
    }));
  };

  handleOnModalClose = (shouldChangeAmount: boolean) => {
    if (shouldChangeAmount) {
      this.setState(
        (prevState) => ({
          item: { ...prevState.item, amount: prevState.item.amount + 1 },
          showInStockModal: false,
        }),
        () => {
          if (this.props.inventoryItem.isCustom) {
            
            this.updateCustomStock({
              variables: {
                updateCustomItemId: this.state.item.productNumber,
                barcode: this.state.item.barcode,
                category: this.state.item.category,
                description: this.state.item.description,
                inStock: this.state.item.amount,
                name: this.state.item.name,
                price: this.state.item.price,
                supplier: this.state.item.supplier,
                units: this.state.item.unit,
              },
            });

            return;
          }
          
          this.updateStock({
            variables: {
              productNumber: this.state.item.productNumber,
              action: "manual",
              automaticOrdering: this.state.item.automaticOrderingState,
              inStock: this.state.item.amount,
              maximum: this.state.item.maximum,
              minimum: this.state.item.minimum,
            },
          });
        }
      );
    } else {
      this.setState({ showInStockModal: false });
    }
  };

  handleOnDeleteModalClose = async (shouldDeleteStock: boolean) => {
    if (shouldDeleteStock) {
      this.props
        .deleteStock({
          variables: {
            productNumber: this.state.item.productNumber,
          },
        })
        .then(() => {
          this.setState({
            showDeleteModal: false,
            justDeleted: true,
          });
          this.props.history.push("/inventory");
        });
    } else {
      this.setState({ showDeleteModal: false });
    }
  };

  handleNumberChange = (e: any, name: any) => {
    const value = e.target.value;
    const valueAsNumber = parseInt(value);

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: clamp(valueAsNumber, 0, 10000),
      },
    }));
  };

  debouncedGoBack = () => {
    const backdrop = document.querySelector(".routing__overlay");
    if (!backdrop) {
      return;
    }
    backdrop.classList.add("u-no-pointer");
    this.props.history.goBack();
  };

  render() {
    const changesSavedStyles = ["EditItem__footer-changesSaved"];
    this.state.savedRecently
      ? changesSavedStyles.splice(1, 1)
      : changesSavedStyles.push("EditItem__footer-changesSaved-no");

    const { item } = this.state;

    const discontinuedStatuses = [90]
    const procuredStatuses = [35]

    if (item === null || item === undefined) {
      return <Redirect to="/inventory" />;
    } else {
      const unit = this.state.item.unit;
      return (
        <div className="EditItem">
          <ChangeInStockModal
            open={this.state.showInStockModal}
            respond={this.handleOnModalClose}
          />
          <DeleteStockModal
            respond={this.handleOnDeleteModalClose}
            open={this.state.showDeleteModal}
          />

          <div className="EditItem__content">
            {this.state.editing && (
              <div className="EditItem__edit">
                <Icon name="editBtn" width={25} />
                <div className="EditItem__edit-text">Afslut redigering</div>
              </div>
            )}

            <div
              className="EditItem__close"
              onClick={() => this.debouncedGoBack()}
            >
              <div className="EditItem__closeborder">
                <Icon name="closeBlue" width={20} height={20} />
              </div>
            </div>

            <div className="EditItem__title">{item.name}</div>

            {item.automaticOrderingState === "disabled" && discontinuedStatuses.includes(item.status)  && !item.isCustom && (
              <div className="EditItem__discontinued">
                <div className="EditItem__discontinued__tag">
                  <div className="EditItem__discontinued__tag-text">Udgået</div>
                </div>
                <Tooltip
                  className="EditItem__discontinued--tooltip"
                  hoverDelay={100}
                  direction="below"
                  content={
                    <div className="EditItem__discontinued--tooltip-content">
                      Varen er udgået hos leverandøren og kan ikke genbestilles.
                    </div>
                  }
                >
                  <Icon name="iconHelp" width={18} height={18} />
                </Tooltip>
              </div>
            )}
            {item.automaticOrderingState === "disabled" && procuredStatuses.includes(item.status) && !item.isCustom && (
              <div className="EditItem__discontinued">
                <div className="EditItem__discontinued__tag">
                  <div className="EditItem__discontinued__tag-text">Skaffevare</div>
                </div>
                <Tooltip
                  className="EditItem__discontinued--tooltip"
                  hoverDelay={100}
                  direction="below"
                  content={
                    <div className="EditItem__discontinued--tooltip-content">
                       Dette er en skaffeavare og kan ikke automatisk genbestilles.
                    </div>
                  }
                >
                  <Icon name="iconHelp" width={18} height={18} />
                </Tooltip>
              </div>
            )}

            <div className="EditItem__item">
              <div className="EditItem__option EditItem__id">
                <ListItem title="Varenummer">
                  {item.isCustom ? (
                    <this.TextInputField
                      value={item.productNumber}
                      name="id"
                      readOnly
                    />
                  ) : (
                    <div className="EditItem__static-text">
                      {item.productNumber}
                    </div>
                  )}
                </ListItem>
              </div>
              <div className="EditItem__option EditItem__description">
                <ListItem title="Beskrivelse">
                  {item.isCustom ? (
                    <this.TextInputField
                      value={item.description}
                      name="description"
                      readOnly
                    />
                  ) : (
                    <div className="EditItem__static-text">
                      {item.description}
                    </div>
                  )}
                </ListItem>
              </div>
              {/* prettier-ignore */}
              {item.amountPerUnit > 1 && (
                <div className="EditItem__option EditItem__amountPerUnit">
                  <ListItem title="Antal pr. pakke">
                    {item.isCustom ? null : (
                      <div className="EditItem__static-text">
                        {`${item.amountPerUnit} stk.`}
                      </div>
                    )}
                  </ListItem>
                </div>
              )}
              <div className="EditItem__option EditItem__barcode">
                <ListItem title="Stregkode">
                  {item.isCustom ? (
                    <this.TextInputField value={item.barcode} name="barcode" />
                  ) : (
                    <div className="EditItem__static-text">{item.barcode}</div>
                  )}
                  {item.barcode ? (
                    <div className="EditItem__print-barcode">
                      <PrintBarcode
                        barcode={item.barcode}
                        name={item.name}
                        prodNo={item.productNumber}
                        description={item.description}
                      />
                    </div>
                  ) : null}
                </ListItem>
              </div>
              {item.isCustom && (
                <div className="EditItem__option EditItem__supplier">
                  <ListItem title="Leverandør">
                    <this.TextInputField
                      value={item.supplier ?? ""}
                      name="supplier"
                      readOnly={false}
                    />
                  </ListItem>
                </div>
              )}
              {item.isCustom && (
                <div className="EditItem__option EditItem__price">
                  <ListItem title="Pris">
                    <NumberInput
                      value={
                        item && item.price
                          ? parseFloat(item.price.toFixed(2))
                          : 0
                      }
                      unit={unit}
                      withUnits={false}
                      withButtons={false}
                      change={(e: any) => {
                        const value = e.target.value;
                        this.setState(
                          (prevState) => ({
                            item: {
                              ...prevState.item,
                              price: isNaN(value) ? 0 : parseFloat(value),
                            },
                          }),
                          () => this.checkIfValueIsBelowMinimum()
                        );
                      }}
                    />
                  </ListItem>
                </div>
              )}
              <div className="EditItem__option EditItem__category">
                <ListItem title="Kategori">
                  {item.isCustom ? (
                    <this.SelectInputField
                      value={item.category}
                      name="category"
                      options={this.state.categoryList ?? []}
                    />
                  ) : (
                    <div className="EditItem__static-text">
                      {" "}
                      {item.category}{" "}
                    </div>
                  )}
                </ListItem>
              </div>
              {this.state.editing ? (
                <div className="EditItem__option EditItem__unit">
                  <ListItem title="Enhed">
                    <this.SelectInputField
                      value={item.unit}
                      name="unit"
                      options={this.state.unitList ?? []}
                    />
                  </ListItem>
                </div>
              ) : null}
              <div className="EditItem__option EditItem__inStock">
                <ListItem title="Aktuel beholdning">
                  <div
                    className="EditItem__currentStock"
                    style={
                      this.props.canEdit ? {} : { justifyContent: "flex-end" }
                    }
                  >
                    {!item.isCustom && (
                      <div className="EditItem__stock-bar">
                        <ProgressBar
                          current={item.amount}
                          min={item.minimum}
                          max={item.maximum}
                        />
                      </div>
                    )}
                    <div
                      className="EditItem__numberInput"
                      style={
                        this.props.canEdit
                          ? {}
                          : { width: "auto", marginLeft: 0 }
                      }
                    >
                      <NumberInput
                        value={item.amount}
                        unit={unit}
                        addOneFunction={() => {
                          if (this.state.showInStockModal === null) {
                            this.setState({ showInStockModal: true });
                          } else {
                            this.handleOnModalClose(true);
                          }
                        }}
                        minusOneFunction={() =>
                          this.setState(
                            (prevState) => ({
                              item: {
                                ...prevState.item,
                                amount: clamp(
                                  prevState.item.amount - 1,
                                  0,
                                  9999
                                ),
                              },
                            }),
                            () => {
                              if (this.props.inventoryItem.isCustom) {
                                
                                this.updateCustomStock({
                                  variables: {
                                    updateCustomItemId: this.state.item
                                      .productNumber,
                                    barcode: this.state.item.barcode,
                                    category: this.state.item.category,
                                    description: this.state.item.description,
                                    inStock: this.state.item.amount,
                                    name: this.state.item.name,
                                    price: this.state.item.price,
                                    supplier: this.state.item.supplier,
                                    units: this.state.item.unit,
                                  },
                                });

                                return;
                              }
                              
                              this.updateStock({
                                variables: {
                                  productNumber: this.state.item.productNumber,
                                  action: "manual",
                                  automaticOrdering: this.state.item
                                    .automaticOrderingState,
                                  inStock: this.state.item.amount,
                                  maximum: this.state.item.maximum,
                                  minimum: this.state.item.minimum,
                                },
                              });
                            }
                          )
                        }
                        change={(e: any) =>
                          this.handleNumberChange(e, "amount")
                        }
                        changeFormat={() => this.checkIfValueIsBelowMinimum()}
                        canEdit={this.props.canEdit}
                      />
                    </div>
                  </div>
                </ListItem>
              </div>
              <div className="EditItem__option EditItem__automatic-ordering">
                {this.props.hasGlobalAutomaticOrdering ? (
                  <ListItem title="Automatisk genbestilling">
                    <div className="EditItem__auto-reorder">
                      <ModalButton
                        buttonStyle="toggleButton"
                        iconName={
                          item.automaticOrderingState ===
                          AutomaticOrdering.Active
                            ? "autoOrderActive"
                            : "orderingDisabled"
                        }
                        labelStyle="toggleLabel"
                        autoOrderState={item.automaticOrderingState}
                        width={16}
                        click={this.toggleAutomaticOrderingState}
                        canEdit={this.props.canEdit}
                      />
                    </div>
                  </ListItem>
                ) : (
                  <ListItem
                    title="Automatisk genbestilling"
                    style={{ color: "#a1b4c4" }}
                  >
                    <div className="EditItem__auto-reorder">
                      <ModalButton
                        buttonStyle="toggleButton"
                        iconName="orderingDisabled"
                        labelStyle="toggleLabel"
                        autoOrderState={"disabled"}
                        width={16}
                        click={noop}
                        canEdit={this.props.canEdit}
                      />
                    </div>
                  </ListItem>
                )}
              </div>
              {!item.isCustom && (
                <>
                  <div className="EditItem__option EditItem__minimum">
                    <ListItem title="Minimumsbeholdning">
                      <div className="EditItem__numberOnlyInput">
                        <NumberInput
                          value={item.minimum}
                          unit={unit}
                          addOneFunction={() =>
                            this.setState(
                              (prevState) => ({
                                item: {
                                  ...prevState.item,
                                  minimum: prevState.item.minimum + 1,
                                },
                              }),
                              () => this.checkIfValueIsBelowMinimum()
                            )
                          }
                          minusOneFunction={() =>
                            this.setState(
                              (prevState) => ({
                                item: {
                                  ...prevState.item,
                                  minimum:
                                    item.minimum - 1 >= 1
                                      ? item.minimum - 1
                                      : 1,
                                },
                              }),
                              () => this.checkIfValueIsBelowMinimum()
                            )
                          }
                          change={(e) => this.handleNumberChange(e, "minimum")}
                          changeFormat={() => this.checkIfValueIsBelowMinimum()}
                          canEdit={this.props.canEdit}
                        />
                      </div>
                    </ListItem>
                  </div>
                  <div className="EditItem__option EditItem__maximum">
                    <ListItem title="Ønsket beholdning">
                      <div className="EditItem__numberOnlyInput">
                        {/* Give it red border and red background of minimum > maximum */}
                        <NumberInput
                          value={item.maximum}
                          unit={unit}
                          addOneFunction={() =>
                            this.setState(
                              (prevState) => ({
                                item: {
                                  ...prevState.item,
                                  maximum: item.maximum + 1,
                                },
                              }),
                              () => this.checkIfValueIsBelowMinimum()
                            )
                          }
                          minusOneFunction={() =>
                            this.setState(
                              (prevState) => ({
                                item: {
                                  ...prevState.item,
                                  maximum:
                                    item.maximum - 1 >= 1
                                      ? item.maximum - 1
                                      : 1,
                                },
                              }),
                              () => this.checkIfValueIsBelowMinimum()
                            )
                          }
                          change={(e) => this.handleNumberChange(e, "maximum")}
                          changeFormat={() => this.checkIfValueIsBelowMinimum()}
                          hasError={
                            this.state.item.minimum > this.state.item.maximum
                          }
                          canEdit={this.props.canEdit}
                        />
                      </div>
                    </ListItem>
                  </div>
                  {item.automaticOrderingState !== "disabled" &&
                    this.props.canEdit && (
                      <>
                        <div className="EditItem__barrier" />
                        <div className="EditItem__option EditItem__maximum">
                          <ListItem title="Manuel bestilling">
                            <div className="EditItem__manual-order">
                              <input
                                className="EditItem__manual-order-input"
                                value={this.state.manualOrder}
                                name="manualOrder"
                                onChange={(e) =>
                                  this.onTextValueChangeHandler(e)
                                }
                              />
                              <div className="EditItem__manual-order__units">
                                {unit}
                              </div>
                              <OrderButton
                                buttonStyle="orderButton"
                                labelStyle="orderLabel"
                                state={this.state.orderButtonState}
                                disabled={this.props.isInBackOrder}
                                click={() => {
                                  if (
                                    item.automaticOrderingState ===
                                    AutomaticOrdering.Disabled
                                  ) {
                                    return;
                                  }
                                  this.setState(
                                    {
                                      orderButtonState: OrderButtonEnum.LOADING,
                                    },
                                    () => {
                                      this.props
                                        .addItemsToCart({
                                          variables: {
                                            items: [
                                              {
                                                manuallyAdded: true,
                                                amount: this.state.manualOrder,
                                                description: item.description,
                                                images: item.images.reduce(
                                                  (acc, current) => {
                                                    if (current) {
                                                      acc.push({
                                                        name: current.name,
                                                        url: current.url,
                                                      });
                                                    }

                                                    return acc;
                                                  },
                                                  [] as {
                                                    name: string;
                                                    url: string;
                                                  }[]
                                                ),
                                                name: item.name,
                                                productNumber:
                                                  item.productNumber,
                                              },
                                            ],
                                          },
                                        })
                                        .then(() => {
                                          this.setState({
                                            orderButtonState:
                                              OrderButtonEnum.ORDERED,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({
                                            orderButtonState:
                                              OrderButtonEnum.READY,
                                          });
                                        })
                                        .finally(() => {
                                          setTimeout(() => {
                                            this.setState({
                                              orderButtonState:
                                                OrderButtonEnum.READY,
                                            });
                                          }, 2000);
                                        });
                                    }
                                  );
                                }}
                              />
                            </div>
                          </ListItem>
                        </div>
                      </>
                    )}
                </>
              )}
              <ItemHistory
                productNumber={this.state.item.productNumber}
                unit={this.state.item.amountPerUnit > 1 ? "ks." : "stk."}
                canEdit={this.props.canEdit}
              />
            </div>
          </div>
          <div className="EditItem__footer">
            {this.props.canEdit && (
              <div
                className="EditItem__footer-delete u-cursor-pointer"
                onClick={() => {
                  this.setState({ showDeleteModal: true });
                }}
              >
                <div className="EditItem__footer-delete-circle">
                  <Icon name="trashcanWhite" width={9} />
                </div>
                <div className="EditItem__footer-text">Slet vare fra lager</div>
              </div>
            )}
            <div className={changesSavedStyles.join(" ")}>
              <div
                className={
                  this.state.savedRecentlyIcon === "buttonSpinner"
                    ? "EditItem__footer-changes-circle EditItem__saving-button-spin"
                    : "EditItem__footer-changes-circle"
                }
              >
                <Icon
                  name={this.state.savedRecentlyIcon as IconName}
                  width={13}
                  height={17}
                />
              </div>
              <div className="EditItem__footer-text">
                {this.state.savedRecentlyIcon === "buttonSpinner"
                  ? "Gemmer"
                  : "Alle ændringer er gemt"}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
