import { useAccountSettingsQuery } from "../../Generated/graphql";
import { BoxLoader } from "../BoxLoader/BoxLoader";
import { SettingsContainer } from "../UserSettings/Core/SettingsContainer";
import "./SettingsDataRetrieval.css";
import { SettingsDataRetrievalContent } from "./SettingsDataRetrievalContent/SettingsDataRetrievalContent";
import { SettingsDataRetrievalContentTurnover } from "./SettingsDataRetrievalContentTurnover/SettingsDataRetrievalContentTurnover";

export const SettingsDataRetrieval = () => {
  const { data, loading, error } = useAccountSettingsQuery();
  if (loading) return <BoxLoader />;
  if (error) return <div>{error.message}</div>;
  return (
    <div className="SettingsDataRetrieval">
      <div className="SettingsDataRetrieval__Title">
        <h1 className="SettingsDataRetrieval__Title-Text">Dataudtræk</h1>
      </div>
      <div className="Settings__dividerbar" />
      <div className="SettingsDataRetrieval__Content">
        <SettingsContainer
          headerTexts={["Handling", "Eksporter"]}
        ></SettingsContainer>
        <div className="SettingsDataRetrieval__Elements">
          <div className="SettingsDataRetrieval__Element">
            <p>Scannede produkter</p>
            <label>Vælg datointerval for dataudtræk</label>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <div className="SettingsDataRetrieval__dates">
              {data && (
                <SettingsDataRetrievalContent settings={data.accountSettings} />
              )}
            </div>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <p>Omsætningshastighed</p>
            <label>Vælg datointerval for dataudtræk</label>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <div className="SettingsDataRetrieval__dates">
              {data && (
                <SettingsDataRetrievalContentTurnover settings={data.accountSettings} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
