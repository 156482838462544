import { FC, PropsWithChildren } from "react";
import { Icon, IconName } from "../Icon/Icon";
import "./NavigationItem.css";

type Props = PropsWithChildren<{
  logoActive: string;
  logoInactive: string;
  active: boolean;
  amount: number;
}>;

export const NavigationItem: FC<Props> = ({
  logoActive,
  logoInactive,
  children,
  active,
  amount,
}) => {
  const navState = ["NavigationItem__content"];

  active ? navState.push("NavigationItem__isActive") : navState.push("");

  const logo = active ? logoActive : logoInactive;

  return (
    <div className="NavigationItem u-cursor-pointer">
      {amount > 0 && (
        <div className="NavigationItem__amount">
          <div className="NavigationItem__amount-text">{amount}</div>
        </div>
      )}
      {active ? <span className="NavigationItem__active-bar" /> : null}
      <div className={navState.join(" ")}>
        <div className="NavigationItem__logo">
          <Icon name={logo as IconName} width={32} height={27} className="Icon__center" />
        </div>
        <div className="NavigationItem__title">{children}</div>
      </div>
    </div>
  );
};
