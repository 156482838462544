import { orderBy } from "lodash";
import { InventoryItem } from "../Generated/graphql";

export const formatExcelData = (rawData: InventoryItem[]) => {
  const total = rawData.find((x) => x.productNumber === "Total værdi")!;

  const productData = rawData.filter(
    (item) => item.productNumber !== "Total værdi"
  );

  const sortedData = orderBy(productData, (x) => x.category);

  const readyData = [...sortedData, total];

  return readyData.map((d) => {

    const fixedPrice =
      d && d.price && d.price.toFixed(2) ? d.price.toFixed(2) : "0";

    if (d.productNumber === "Total værdi") {
      return {
        "Produkt nummer": d.productNumber,
        Navn: " ",
        Antal: " ",
        Værdi: parseFloat(fixedPrice),
      };
    }

    const name = d.name;
    const category = d.category;

    const safeAmount = d && d.amount ? d.amount : 0;

    const averagePrice = fixedPrice && parseFloat(fixedPrice) > 0 && (parseFloat(fixedPrice) / safeAmount).toFixed(2) ? (parseFloat(fixedPrice) / safeAmount).toFixed(2) : "0";

    return {
      "Produkt nummer": d.productNumber,
      Navn: name,
      Beskrivelse: d.description,
      Kategori: category,
      Antal: d.amount,
      "Pris pr. stk.": parseFloat(averagePrice),
      Værdi: parseFloat(fixedPrice),
    };
  });
};
