// frontend/src/Components/SettingsDataRetrieval/SettingsDataRetrievalContentTurnover/SettingsDataRetrievalContentTurnover.tsx
import { useQuery } from "@apollo/client";
import { da } from "date-fns/locale";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { FC, useState } from "react";
//@ts-ignore
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, Zoom } from "react-toastify";
import * as XLSX from "xlsx";
import { AccountSettings } from "../../../Generated/graphql";
import { INVENTORY_TURNOVER } from "../../../GraphQL/Queries/inventoryTurnover.query";
import { Button } from "../../Button/Button";
import "./SettingsDataRetrievalContentTurnover.css";

registerLocale("da", da);

type Props = {
  settings: AccountSettings;
};

type TurnoverMetric = {
  value: number;
  productNumber?: string;
  productName?: string;
  category?: string;
};

type InventoryTurnoverData = {
  inventoryTurnover: {
    globalTurnover: number;
    productTurnover: TurnoverMetric[];
    categoryTurnover: TurnoverMetric[];
    startDate: string;
    endDate: string;
  };
};

type InventoryTurnoverVars = {
  startDate?: string;
  endDate?: string;
  interval?:
    | "YTD"
    | "LAST_MONTH"
    | "LAST_30_DAYS"
    | "LAST_12_MONTHS"
    | "LAST_QUARTER"
    | "CUSTOM";
};

export const SettingsDataRetrievalContentTurnover: FC<Props> = ({
  settings,
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { data, loading } = useQuery<
    InventoryTurnoverData,
    InventoryTurnoverVars
  >(INVENTORY_TURNOVER, {
    variables: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      interval: "CUSTOM",
    },
    skip: !settings.id,
  });

  const handleExcelExport = () => {
    if (!data?.inventoryTurnover) return;

    const workbook = XLSX.utils.book_new();

    // Product Turnover Sheet
    const productData = data.inventoryTurnover.productTurnover.map((item) => ({
      "Product Number": item.productNumber || "",
      "Product Name": item.productName || "",
      Category: item.category || "",
      "Turnover Rate": item.value,
    }));
    const productSheet = XLSX.utils.json_to_sheet(productData);
    XLSX.utils.book_append_sheet(workbook, productSheet, "Product Turnover");

    // Category Turnover Sheet
    const categoryData = data.inventoryTurnover.categoryTurnover.map(
      (item) => ({
        Category: item.category || "",
        "Turnover Rate": item.value,
      })
    );
    const categorySheet = XLSX.utils.json_to_sheet(categoryData);
    XLSX.utils.book_append_sheet(workbook, categorySheet, "Category Turnover");

    XLSX.writeFile(
      workbook,
      `inventory_turnover_${startDate.toISOString().split("T")[0]}_${
        endDate.toISOString().split("T")[0]
      }.xlsx`
    );

    toast("Excel export completed successfully", {
      className: "u-toast-success",
      progressClassName: "u-toast-success-bar",
      transition: Zoom,
    });
  };

  const handlePdfExport = () => {
    if (!data?.inventoryTurnover) return;

    const doc = new jsPDF();
    let yPosition = 15;

    // Add title and period
    doc.text("Inventory Turnover Report", 14, yPosition);
    yPosition += 10;

    doc.text(
      `Period: ${new Date(
        data.inventoryTurnover.startDate
      ).toLocaleDateString()} - ${new Date(
        data.inventoryTurnover.endDate
      ).toLocaleDateString()}`,
      14,
      yPosition
    );
    yPosition += 10;

    doc.text(
      `Global Turnover Rate: ${data.inventoryTurnover.globalTurnover.toFixed(
        2
      )}`,
      14,
      yPosition
    );
    yPosition += 10;

    // Product Turnover Table
    const productTableData = data.inventoryTurnover.productTurnover.map(
      (item) => [
        item.productNumber || "",
        item.productName || "",
        item.category || "",
        item.value.toFixed(2),
      ]
    );

    autoTable(doc, {
      head: [["Product Number", "Product Name", "Category", "Turnover Rate"]],
      body: productTableData,
      startY: yPosition,
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fillColor: [71, 71, 71] },
      didDrawPage: (tableData) => {
        if (tableData?.cursor?.y) {
          yPosition = tableData.cursor.y;
        } else {
          yPosition += 100;
        }
      },
    });

    yPosition += 15;

    // Category Turnover Table
    const categoryTableData = data.inventoryTurnover.categoryTurnover.map(
      (item) => [item.category || "", item.value.toFixed(2)]
    );

    autoTable(doc, {
      head: [["Category", "Turnover Rate"]],
      body: categoryTableData,
      startY: yPosition,
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fillColor: [71, 71, 71] },
    });

    doc.save(
      `inventory_turnover_${startDate.toISOString().split("T")[0]}_${
        endDate.toISOString().split("T")[0]
      }.pdf`
    );

    toast("PDF export completed successfully", {
      className: "u-toast-success",
      progressClassName: "u-toast-success-bar",
      transition: Zoom,
    });
  };

  return (
    <>
      <div className="SettingsDataRetrieval__Content">
        <div className="SettingsDataRetrieval__Elements">
          <div className="SettingsDataRetrieval__Element">
            <p>Startdato</p>
            <label>Vælg startdato for perioden</label>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <div className="SettingsDataRetrieval__date-picker">
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                dateFormat="dd-MM-yyyy"
                locale="da"
              />
            </div>
          </div>

          <div className="SettingsDataRetrieval__Element">
            <p>Slutdato</p>
            <label>Vælg slutdato for perioden</label>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <div className="SettingsDataRetrieval__date-picker">
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                dateFormat="dd-MM-yyyy"
                locale="da"
              />
            </div>
          </div>

          <div className="SettingsDataRetrieval__Element">
            <p>Eksport muligheder</p>
            <label>Vælg dit ønskede format</label>
          </div>
          <div className="SettingsDataRetrieval__Element">
            <div className="SettingsDataRetrieval__actions">
              <Button
                buttonStyle="newItem"
                labelStyle="createButton"
                click={handleExcelExport}
                inactive={loading || !data?.inventoryTurnover}
              >
                Export til Excel
              </Button>
              <Button
                buttonStyle="newItem"
                labelStyle="createButton"
                click={handlePdfExport}
                inactive={loading || !data?.inventoryTurnover}
              >
                Export til PDF
              </Button>
            </div>
          </div>

          {data?.inventoryTurnover && (
            <div className="SettingsDataRetrieval__Element">
              <p>
                Global Turnover Rate:{" "}
                {data.inventoryTurnover.globalTurnover.toFixed(2)}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
