import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import {
  InventoryItemHistoryDocument,
  InventoryItemHistoryEntry,
  useRegretInventoryItemHistoryEntryMutation,
} from "../../../Generated/graphql";
import { Icon } from "../../Icon/Icon";
import { UndoStockChangeModal } from "../UndoStockChangeModal/UndoStockChangeModal";
import "./ItemHistoryEntry.css";

type Props = {
  entry: InventoryItemHistoryEntry;
  relatedEntries?: InventoryItemHistoryEntry[];
  unit: string;
  canEdit: boolean;
};

export const ItemHistoryEntry: FC<Props> = ({
  entry,
  unit,
  canEdit,
  relatedEntries,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [changed, setChanged] = useState(entry.eventReverted);

  const entryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (entry.fromOrderNumber && relatedEntries && entryRef.current) {
      const related = relatedEntries.filter(
        (e) => e.fromOrderNumber === entry.fromOrderNumber && e.id !== entry.id
      );

      console.log({related,entry})

      if (related.length > 0) {
        // Find the related elements and create connections
        related.forEach((relatedEntry) => {
          const relatedElement = document.querySelector(
            `[data-entry-id="${relatedEntry.id}"]`
          ) as HTMLElement; // Cast to HTMLElement

          if (relatedElement && entryRef.current) {
            const line = document.createElement("div");
            line.className = "ItemHistoryEntry__connection-line";

            // Now TypeScript knows these are HTMLElements with offsetTop
            const startY = entryRef.current.offsetTop;
            const endY = relatedElement.offsetTop;
            const height = Math.abs(endY - startY);

            line.style.top = `${Math.min(startY, endY)}px`;
            line.style.height = `${height}px`;

            entryRef.current.appendChild(line);
          }
        });
      }
    }
  }, [entry, relatedEntries]);

  const [regretEvent] = useRegretInventoryItemHistoryEntryMutation({
    update: (cache, { data }) => {
      setChanged(true);
      const prevData = cache.readQuery<{
        inventoryItemHistory: InventoryItemHistoryEntry[];
      }>({
        query: InventoryItemHistoryDocument,
        variables: { productNumber: entry.productNumber },
      });

      const regretInventoryItemHistoryEntry =
        data?.regretInventoryItemHistoryEntry;

      if (!regretInventoryItemHistoryEntry) {
        return;
      }

      const newData = {
        ...prevData,
        inventoryItemHistory: prevData?.inventoryItemHistory.map((event) => {
          if (event.id === regretInventoryItemHistoryEntry.id) {
            return regretInventoryItemHistoryEntry;
          } else {
            return event;
          }
        }),
      };

      cache.writeQuery({
        query: InventoryItemHistoryDocument,
        data: newData,
        variables: { prodNo: entry.productNumber },
      });
    },
  });

  const formattedDate = moment(entry.eventTime).format("DD.MM.YYYY HH.mm");
  const formattedEventLocation =
    entry.eventAction === "manual"
      ? "Manuel redigering"
      : entry.eventAction === "scan"
      ? "Scan"
      : entry.eventAction === "receive"
      ? `Modtagelse${
          entry.fromOrderNumber ? ` - Ordre ${entry.fromOrderNumber}` : ""
        }`
      : entry.eventAction === "ordered"
      ? `Bestilling${
          entry.fromOrderNumber ? ` - Ordre ${entry.fromOrderNumber}` : ""
        }`
      : "Ukendt";

  const ordered = entry.eventAction === "ordered";

  const handleResponse = async (response: boolean) => {
    if (response) {
      setShowModal(false);
      await regretEvent({
        variables: {
          regretInventoryItemHistoryEntryId: entry.id,
        },
      });
    } else {
      setShowModal(false);
    }
  };

  return (
    <div
      ref={entryRef}
      className={`ItemHistoryEntry ItemHistoryEntry--${entry.eventAction.toLowerCase()}`}
      data-entry-id={entry.id}
    >
      {entry.fromOrderNumber && (
        <div className="ItemHistoryEntry__connection-dot" />
      )}
      <UndoStockChangeModal open={showModal} respond={handleResponse} />
      <div className="ItemHistoryEntry__information">
        <div
          className="ItemHistoryEntry__information-date"
          style={
            changed
              ? {
                  textDecoration: "line-through",
                  color: "#0C2C52",
                  opacity: 0.3,
                }
              : undefined
          }
        >
          {formattedDate}
        </div>
        <div
          className="ItemHistoryEntry__information-location"
          style={
            changed
              ? {
                  textDecoration: "line-through",
                  color: "#0C2C52",
                  opacity: 0.3,
                }
              : undefined
          }
        >
          {formattedEventLocation}
        </div>
        {changed ? (
          <div className="ItemHistoryEntry__information-revertTime">
            <div className="ItemHistoryEntry__information-revertTime-icon">
              <Icon name="undoIcon" width={13} />
            </div>
            <div className="ItemHistoryEntry__information-revertTime-text">
              {moment(entry.revertTime).format("DD.MM.YYYY HH.mm")}
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="ItemHistoryEntry__amount"
        style={
          changed
            ? { backgroundColor: "#f4f4f4" }
            : ordered
            ? { backgroundColor: "#fff" }
            : entry.eventType === "add"
            ? { backgroundColor: "rgba(83,166,130,0.1)" }
            : { backgroundColor: "rgba(170,37,37,0.1)" }
        }
      >
        <div
          className="ItemHistoryEntry__amount-number"
          style={
            changed
              ? { textDecoration: "line-through", color: "#c7c7c7" }
              : ordered
              ? {}
              : entry.eventType === "add"
              ? { color: "#53A682" }
              : { color: "#AA2525" }
          }
        >
          {ordered ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.2em",
                marginLeft: "2em",
              }}
            >
              <span style={{ display: "grid", placeItems: "center" }}>
                <Icon name="cartListviewGreen" width={18} height={19} style={{transform: 'translateX(-0.2em)'}} pathStyle={{fill: "#0c2c52"}} />
              </span>
              <span>
                <p>
                  {entry.productIds.length} {unit}
                </p>
              </span>
            </div>
          ) : entry.eventType === "add" ? (
            // `+ ${entry.productIds.length} ${unit}`
            <div style={{ display: "flex", gap: "0.3em", marginLeft: "2em" }}>
              <span
                style={{
                  transform: "rotate(180deg)",
                  display: "grid",
                  placeItems: "baseline",
                  height: "100%",
                }}
              >
                <Icon name="tendencyPositive" width={15} height={15} />
              </span>
              <span>
                <p>
                  {entry.productIds.length} {unit}
                </p>
              </span>
            </div>
          ) : (
            // `- ${entry.productIds.length} ${unit}`
            <div style={{ display: "flex", gap: "0.3em", marginLeft: "2em" }}>
              <span
                style={{
                  transform: "rotate(180deg)",
                  display: "grid",
                  placeItems: "baseline",
                  height: "100%",
                }}
              >
                <Icon name="tendencyNegative" width={15} height={15} />
              </span>
              <span>
                <p>
                  {entry.productIds.length} {unit}
                </p>
              </span>
            </div>
          )}
        </div>
      </div>
      {canEdit && entry.eventAction !== "ordered" ? (
        <div
          className={
            changed ||
            moment(new Date())
              .subtract(48, "hours")
              .isAfter(moment(entry.eventTime))
              ? "ItemHistoryEntry__undo ItemHistoryEntry__undo__hidden"
              : "ItemHistoryEntry__undo u-cursor-pointer"
          }
          onClick={() => {
            if (
              !changed &&
              moment(new Date())
                .subtract(48, "hours")
                .isBefore(moment(entry.eventTime))
            ) {
              setShowModal(true);
            }
          }}
        >
          <div className="ItemHistoryEntry__undo-button">
            <Icon name="undoIcon" width={19} />
          </div>
        </div>
      ) : (
        <div
          className={"ItemHistoryEntry__undo ItemHistoryEntry__undo__hidden"}
        >
          <div className="ItemHistoryEntry__undo-button">
            <Icon name="undoIcon" width={19} />
          </div>
        </div>
      )}
    </div>
  );
};
