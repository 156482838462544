// frontend/src/Components/SettingsAdmin/SettingsAdminContent/SettingsAdminContent.tsx
import { FC } from "react";
import { toast, Zoom } from "react-toastify";
import {
  AccountSettings,
  useRevertSyncHistoricMutation,
  useRevertSyncMutation,
  useSyncItemsHistoricMutation,
  useSyncItemsMutation,
  useSyncItemsWithLocalOrdersMutation,
  useSyncOrdersMutation,
} from "../../../Generated/graphql";
import { Button } from "../../Button/Button";
import "./SettingsAdminContent.css";

type Props = {
  settings: AccountSettings;
};

export const SettingsAdminContent: FC<Props> = ({ settings }) => {
  const [syncOrders, { loading: loadingOrders }] = useSyncOrdersMutation({
    onCompleted: () => {
      toast("Synkronisering af ordrer startet", {
        className: "u-toast-success",
        progressClassName: "u-toast-success-bar",
        transition: Zoom,
      });
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af ordrer", {
        transition: Zoom,
      });
      console.error("Error syncing orders:", error);
    },
  });

  const [syncStockWithOrdersLocal, { loading: loadingStockLocal }] = useSyncItemsWithLocalOrdersMutation({
    onCompleted: () => {
      toast("Synkroniserede lagerværdierne med ordre", {
        className: "u-toast-success",
        progressClassName: "u-toast-success-bar",
        transition: Zoom,
      });
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af lagerværdier med ordre", {
        transition: Zoom,
      });
      console.error("Error syncing stock with orders (Inventory DB instance):", error);
    },
  });

  const [syncProductStockWithOrderData, { loading: loadingStock, reset }] = useSyncItemsMutation({
    onCompleted: (data) => {
      if (data.syncItems.success && data.syncItems.transactionId) {
        toast.success("Synkronisering af varer startet", {
          transition: Zoom,
        });
        localStorage.setItem('lastSyncTransactionId', data.syncItems.transactionId);
      } else {
        // Handle unsuccessful sync
        toast.error("Synkronisering kunne ikke gennemføres", {
          transition: Zoom,
        });
        // Reset the mutation state
        reset();
        // Clear any existing transactionId since sync failed
        localStorage.removeItem('lastSyncTransactionId');
      }
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af varer", {
        transition: Zoom,
      });
      reset();
      localStorage.removeItem('lastSyncTransactionId');
    }
  });

  const [revertSyncProductStockWithOrderData, { loading: loadingRevertStock }] = useRevertSyncMutation({
    onCompleted: () => {
      toast("Synkronisering af lagerværider startet", {
        className: "u-toast-success",
        progressClassName: "u-toast-success-bar",
        transition: Zoom,
      });
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af lagerværdier", {
        transition: Zoom,
      });
      console.error("Error syncing product stock:", error);
    },
  });

  const handleSync = () => {
    syncProductStockWithOrderData({
      onError: () => {
        reset();
        localStorage.removeItem('lastSyncTransactionId');
        toast.error("Synkronisering fejlede", {
          transition: Zoom,
        });
      }
    });
  };


  const handleRevert = () => {
    const transactionId = localStorage.getItem('lastSyncTransactionId');
    if (transactionId) {
      revertSyncProductStockWithOrderData({ 
        variables: { transactionId },
        onError: () => {
          toast.error("Fejl ved tilbageførsel", {
            transition: Zoom,
          });
        }
      });
    } else {
      toast.error("Ingen synkronisering at tilbageføre", {
        transition: Zoom,
      });
    }
  };

  const [syncProductStockWithHistoricOrderData, { loading: loadingStockHistoric }] = useSyncItemsHistoricMutation({
    onCompleted: (data) => {
      if (data.syncItemsHistoric.success && data.syncItemsHistoric.transactionId) {
        toast.success("Synkronisering af varer startet", {
          transition: Zoom,
        });
        localStorage.setItem('lastSyncTransactionId', data.syncItemsHistoric.transactionId);
      } else {
        // Handle unsuccessful sync
        toast.error("Synkronisering kunne ikke gennemføres", {
          transition: Zoom,
        });
        // Reset the mutation state
        reset();
        // Clear any existing transactionId since sync failed
        localStorage.removeItem('lastSyncTransactionId');
      }
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af varer", {
        transition: Zoom,
      });
      reset();
      localStorage.removeItem('lastSyncTransactionId');
    }
  });

  const [revertSyncProductStockWithHistoricOrderData, { loading: loadingRevertStockHistoric }] = useRevertSyncHistoricMutation({
    onCompleted: () => {
      toast("Synkronisering af lagerværider startet", {
        className: "u-toast-success",
        progressClassName: "u-toast-success-bar",
        transition: Zoom,
      });
    },
    onError: (error) => {
      toast.error("Fejl ved synkronisering af lagerværdier", {
        transition: Zoom,
      });
      console.error("Error syncing product stock:", error);
    },
  });

  const handleSyncHistoric = () => {
    syncProductStockWithHistoricOrderData({
      onError: () => {
        reset();
        localStorage.removeItem('lastSyncTransactionId');
        toast.error("Synkronisering fejlede", {
          transition: Zoom,
        });
      }
    });
  };


  const handleRevertHistoric = () => {
    const transactionId = localStorage.getItem('lastSyncTransactionId');
    if (transactionId) {
      revertSyncProductStockWithHistoricOrderData({ 
        variables: { transactionId },
        onError: () => {
          toast.error("Fejl ved tilbageførsel", {
            transition: Zoom,
          });
        }
      });
    } else {
      toast.error("Ingen synkronisering at tilbageføre", {
        transition: Zoom,
      });
    }
  };


  return (
    <div className="SettingsAdminContent__Content">
      <div className="SettingsAdminContent__Element">
        <div className="SettingsAdminContent__actions">
          <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            click={() => syncOrders()}
            inactive={loadingOrders}
          >
            Synkroniser ordre med BC
          </Button>
        </div>
      </div>
      <div className="SettingsAdminContent__Element">
        <div className="SettingsAdminContent__actions">
          <Button
            buttonStyle="newItem"
            labelStyle="createButton"
            click={() => syncStockWithOrdersLocal()}
            inactive={loadingStockLocal}
          >
            Synkroniser lagerværdier med ordre (Lokalt)
          </Button>
        </div>
      </div>
      <div className="SettingsAdminContent__Element">
        <div className="SettingsAdminContent__actions">
        <Button
              buttonStyle="newItem"
              labelStyle="createButton"
              click={() => handleSync()}
              inactive={loadingStock}
            >
              Overskriv lagerværdier med ordre (BC)
            </Button>
            {localStorage.getItem('lastSyncTransactionId') && (
              <Button
                buttonStyle="warning"
                labelStyle="createButton"
                click={handleRevert}
                inactive={loadingRevertStock}
              >
                Fortryd sidste synkronisering af lagerværdier med ordre
              </Button>
            )}
        </div>
      </div>
      <div className="SettingsAdminContent__Element">
        <div className="SettingsAdminContent__actions">
        <Button
              buttonStyle="newItem"
              labelStyle="createButton"
              click={() => handleSyncHistoric()}
              inactive={loadingStockHistoric}
            >
              Overskriv lagerværdier med historirske ordre (VISMA)
            </Button>
            {localStorage.getItem('lastSyncTransactionId') && (
              <Button
                buttonStyle="warning"
                labelStyle="createButton"
                click={handleRevertHistoric}
                inactive={loadingRevertStockHistoric}
              >
                Fortryd sidste synkronisering af lagerværdier med historiske ordre
              </Button>
            )}
        </div>
      </div>
      
    </div>
  );
};
